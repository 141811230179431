// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-index-page-tsx": () => import("./../../../src/pages/index/index.page.tsx" /* webpackChunkName: "component---src-pages-index-index-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-post-post-page-tsx": () => import("./../../../src/pages/post/post.page.tsx" /* webpackChunkName: "component---src-pages-post-post-page-tsx" */),
  "component---src-pages-static-legal-legal-page-tsx": () => import("./../../../src/pages/static/legal/legal.page.tsx" /* webpackChunkName: "component---src-pages-static-legal-legal-page-tsx" */),
  "component---src-pages-static-privacy-privacy-page-tsx": () => import("./../../../src/pages/static/privacy/privacy.page.tsx" /* webpackChunkName: "component---src-pages-static-privacy-privacy-page-tsx" */)
}

